import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../templates/Home.vue'
import Login from '../templates/Login.vue'
import firebase from "firebase";

Vue.use(VueRouter)

if (window.location.hostname === 'homolog.dataland.ai' || window.location.hostname === 'app.dataland.ai') {
    window.location.href = "/redirect_page.html";
}

const routes = [{
        path: '/',
        redirect: '/auth/login'
    },
    {
        path: '/hp',
        beforeEnter: _beforeEnter,
        name: 'Hp',
        component: Home,
        children: [{
            path: '',
            name: 'Home',
            component: () =>
                import ('@/views/Home.vue')
        }]
    },
    {
        path: '/auth',
        name: 'Auth',
        component: Login,
        children: [{
                path: 'login',
                name: 'Login',
                component: () =>
                    import ('@/views/auth/Login.vue')
            },
            {
                path: 'signup',
                name: 'Signup',
                component: () =>
                    import ('@/views/auth/Signup.vue')
            },
            {
                path: 'forgot-password',
                name: 'forgot-password',
                component: () =>
                    import ('@/views/auth/Forgot-password.vue')
            }
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

function _beforeEnter(to, from, next) {
    if (!router.app.$session.exists()) {
        next('/');
        return
    } else {
        firebase.analytics().setUserProperties({ email: router.app.$session.get('email') });
        firebase.analytics().setUserId(router.app.$session.get('email'))
        firebase.analytics().logEvent("navigate", { "to": to.path, "from": from.path })
        next();
    }
}

export default router